export function checkLength(str, maxLength, trimLength) {
	return str ? (str.length > maxLength ? str.substr(0, trimLength) + '...' : str) : '';
}

export function getCdnUrl(url) {
	return url.replace(
		'https://inmobi-rm-app.s3.us-east-1.amazonaws.com',
		'https://i.l.inmobicdn.net/rm',
		'https://inmobirmapp.blob.core.windows.net/inmobi-rm-app/CreativeRepository',
	);
}

export function getFileType(url) {
	const extension = url
		.split(/[#?]/)[0]
		.split('.')
		.pop()
		.trim();
	switch (extension) {
		case 'mp4':
			return 'video';
		case 'jpg':
			return 'image';
		case 'png':
			return 'image';
		case 'gif':
			return 'image';
		default:
			return undefined;
	}
}
