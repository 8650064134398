import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AdFormatDetails from './AdFormatDetails';
import AdFormatExamples from './AdFormatExamples';
import './AdFormatContainer.scss';
const AdFormatContainer = ({ adFormatDetails }) => {
	return (
		<div className="creative-format-container">
			<AdFormatDetails details={adFormatDetails} />
			<AdFormatExamples
				featured={adFormatDetails.featured}
				creatives={adFormatDetails.creatives}
				adFormatType={adFormatDetails.name}
			/>
		</div>
	);
};

export default AdFormatContainer;
