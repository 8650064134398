import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from '@reach/router';

import logo from '../assets/images/creative-labs-logo.png';
import { fireEvent } from '../utils/tracking';

import './AppDrawer.scss';

const AppDrawer = ({ drawerOpen, toggleDrawer, menuItems, isLoggedInn }) => {
	console.log('appdrawer', isLoggedInn);
	return (
		<SwipeableDrawer
			anchor={'left'}
			open={drawerOpen}
			onOpen={() => toggleDrawer(true)}
			onClose={() => toggleDrawer(false)}
		>
			<div
				className="appDrawerContents"
				onClick={() => toggleDrawer(false)}
				onKeyDown={() => toggleDrawer(false)}
			>
				<List>
					<Link to="/" className="appDrawerLogo">
						<ListItem>
							<div className="logoCtn">
								<img src={logo} alt="ThinkLoud Logo" />
								{/* <div className="logoSubText">We bring more color to your campaigns</div> */}
							</div>
						</ListItem>
					</Link>
				</List>
				<Divider />
				<List className="listContainer">
					{menuItems.map(item => (
						<Link
							to={item.link}
							className="menuItem"
							key={item.name}
							onClick={() => fireEvent('App Drawer', 'Click', `${item.name} Clicked`)}
						>
							<ListItem button>
								{/* <ListItemIcon className="menuItemIcon">
									<Icon>{item.icon}</Icon>
								</ListItemIcon> */}
								<ListItemText primary={item.name} className="menuItemText" />
							</ListItem>
						</Link>
					))}
					{!isLoggedInn ? (
						<ListItem button>
							{/* <ListItemIcon className="menuItemIcon">
								<Icon>home</Icon>
							</ListItemIcon> */}
							<ListItemText primary="LOGIN" className="menuItemText" />
						</ListItem>
					) : (
						<ListItem button>
							<ListItemIcon className="menuItemIcon">
								<Icon>home</Icon>
							</ListItemIcon>
							<ListItemText primary="LOGOUT" className="menuItemText" />
						</ListItem>
					)}
				</List>
			</div>
		</SwipeableDrawer>
	);
};

export default AppDrawer;
