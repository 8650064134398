import React, { useEffect } from 'react';
import { Link } from '@reach/router';
import Button from '@material-ui/core/Button';
import VerticalExampleCard from './VerticalExampleCard';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { useLocation, useNavigate } from '@reach/router';
import './VerticalExamples.scss';

const VerticalExamples = ({ examples, verticalType, creatives }) => {
	const navigate = useNavigate();
	const location = useLocation();
	let exampleList = [];

	if (Array.isArray(examples) && examples.length) {
		exampleList = examples;
	} else {
		exampleList = creatives;
	}

	const openFunctionality = filterName => {
		//navigate(`${location.origin}/?functionalities=${filterName}`)
	};
	return (
		<div className="veticalExamplesContainer">
			<div className="topHeader">
				<div className="left">EXAMPLES</div>
				<div className="liner"></div>
				<div className="right">
					{/* <Link to={`/gallery?verticals=${verticalType}`}>
						
						<div
							className="cardViewBtn"
							onClick={() => {
								openFunctionality(verticalType);
							}}
						>
							SEE MORE
						</div>
					</Link> */}
					<Button variant="contained" className="button" href={`/gallery?verticals=${verticalType}`}>
						SEE MORE
					</Button>
				</div>
			</div>
			<div className="topHeaderMobile">EXAMPLES</div>
			<div className="mainContainer">
				{exampleList ? (
					<div className="exampleList">
						{exampleList.map((details, index) => (
							<VerticalExampleCard details={details} key={index} />
						))}
					</div>
				) : (
					<></>
				)}

				<div className="carouselMobileContainer">
					{exampleList ? (
						<Carousel
							showArrows={true}
							showThumbs={false}
							showIndicators={false}
							infiniteLoop
							swipeable={true}
							className="creativeCarousel"
							statusFormatter={(current, total) => ``}
						>
							{exampleList.map((details, index) => {
								return <VerticalExampleCard details={details} key={index} />;
							})}
						</Carousel>
					) : (
						<></>
					)}
				</div>
			</div>
			<div className="seeMoreMobileContainer">
				{/* <Link to={`/gallery?verticals=${verticalType}`}>
					<div
						className="cardViewBtn"
						onClick={() => {
							openFunctionality(verticalType);
						}}
					>
						SEE MORE
					</div>
				</Link> */}
				<Button variant="contained" className="button" href={`/gallery?verticals=${verticalType}`}>
					SEE MORE
				</Button>
			</div>
		</div>
	);
};
const mapStateToProps = state => {
	return {
		examples: state.verticalReducer.selectedVertical.featured,
		creatives: state.verticalReducer.selectedVertical.creatives,
	};
};
export default connect(mapStateToProps)(VerticalExamples);
