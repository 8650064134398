import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './components/AppRouter';
import './index.css';
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';
import { initGA } from './utils/tracking';

initGA();
ReactDOM.render(<AppRouter store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
