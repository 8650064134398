import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MailOutlineSharpIcon from '@material-ui/icons/MailOutlineSharp';
import { fireEvent } from '../utils/tracking';
import './Footer.scss';

const sendMail = () => {
	fireEvent('Footer', 'Click', `Mail Button Clicked`);
	window.location.href = 'mailto:shantesh.gogi@inmobi.com';
};

const Footer = () => {
	return (
		<footer className="footer">
			<div id="footer-content" className="footerContent">
				<div>&copy; InMobi Pte Ltd. All rights reserved.</div>
				<div className="mailDetail">
					<Tooltip title="shantesh.gogi@inmobi.com">
						<IconButton className="mailBtn" onClick={sendMail}>
							<MailOutlineSharpIcon className="mailIcon" />
						</IconButton>
					</Tooltip>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
