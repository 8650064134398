import axios from 'axios';
import {
	FETCHING_SEARCH_RESULTS,
	FETCH_FAILURE,
	FETCH_SEARCH_SUCCESS,
	CLOSE_SEARCH_RESULTS,
	FETCH_SEARCH_NOTFOUND,
} from './SearchActionTypes';

export const fetchingSearchResults = () => {
	return {
		type: FETCHING_SEARCH_RESULTS,
		payload: null,
	};
};

export const fetchFailure = error => {
	return {
		type: FETCH_FAILURE,
		payload: error,
	};
};

export const fetchSearchSuccess = searchResults => {
	return {
		type: FETCH_SEARCH_SUCCESS,
		payload: searchResults,
	};
};

export const closeSearchResults = () => {
	return {
		type: CLOSE_SEARCH_RESULTS,
		payload: null,
	};
};
export const fetchSearchNoDataFound = () => {
	return {
		type: FETCH_SEARCH_NOTFOUND,
		payload: null,
	};
};

export const fetchSearch = searchInput => dispatch => {
	console.log('SearchInput', searchInput);
	if (searchInput) {
		dispatch(fetchingSearchResults());
		axios({
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/graphql`,
			data: {
				query: `
                    query{
                        creatives(where: {title_contains: "${searchInput}"}){
                            id
                            title
                            description
							slug
							post_sales
                        }
                        brands(where: {name_contains: "${searchInput}"}){
                            creatives{
                                id
                                title
                                description
								slug
								post_sales
                            }
                        }
                        functionalities(where: {name_contains: "${searchInput}"}){
                            creatives{
                                id
                                title
                                description
								slug
								post_sales
                            }
                        }
                        specialtags(where: {name_contains: "${searchInput}"}){
                            creatives{
                                id
                                title
                                description
								slug
								post_sales
                            }
                        }
                        subbrands(where: {name_contains: "${searchInput}"}){
                            creatives{
                                id
                                title
                                description
								slug
								post_sales
                            }
						}
						countries(where: {name_contains: "${searchInput}"}){
                            creatives{
                                id
                                title
                                description
								slug
								post_sales
                            }
						}
						formats(where: {name_contains: "${searchInput}"}){
                            creatives{
                                id
                                title
                                description
								slug
								post_sales
                            }
						}
						verticals(where: {name_contains: "${searchInput}"}){
                            creatives{
                                id
                                title
                                description
								slug
								post_sales
                            }
                        }
                    }
                `,
			},
		})
			.then(response => {
				console.log(response);
				// if (response.data.data.creatives == null) {
				// 	return dispatch(fetchSearchNoDataFound());
				// } else
				//  {
				const set = new Set();

				if (response.data.data.creatives == null) {
					response.data.data.creatives = [];
				} else {
					response.data.data.creatives.forEach(item => set.add(JSON.stringify(item)));
				}

				response.data.data.brands.forEach(brand => {
					brand.creatives.forEach(item => set.add(JSON.stringify(item)));
				});
				response.data.data.functionalities.forEach(functionality => {
					functionality.creatives.forEach(item => set.add(JSON.stringify(item)));
				});
				response.data.data.specialtags.forEach(specialtag => {
					specialtag.creatives.forEach(item => set.add(JSON.stringify(item)));
				});
				response.data.data.subbrands.forEach(subbrand => {
					subbrand.creatives.forEach(item => set.add(JSON.stringify(item)));
				});
				response.data.data.countries.forEach(countries => {
					countries.creatives.forEach(item => set.add(JSON.stringify(item)));
				});
				response.data.data.formats.forEach(formats => {
					formats.creatives.forEach(item => set.add(JSON.stringify(item)));
				});
				response.data.data.verticals.forEach(verticals => {
					verticals.creatives.forEach(item => set.add(JSON.stringify(item)));
				});
				return dispatch(fetchSearchSuccess(Array.from(set).map(JSON.parse)));
				// }
			})
			.catch(err => dispatch(fetchFailure(err)));
	} else {
		return dispatch(fetchSearchSuccess([]));
	}
};
