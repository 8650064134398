import React, { useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';
import Loader from '../components/Loader';
import HomeBanner from '../components/Functionality/HomeBanner';
import FunctionalityList from '../components/Functionality/FunctionalityList';
import { connect } from 'react-redux';
import { fetchFunctionalities } from '../redux/functionality/FunctionalityActions';
import Error from '../components/Error';
import CubeIcon from '../components/CubeIcon';
import './../components/Functionality/FunctionalityList.scss';
const FunctionalitiesList = ({ loading, functionalities, error, fetchFunctionalities }) => {
	useEffect(() => {
		fetchFunctionalities();
	}, [fetchFunctionalities]);

	return error ? (
		<Error error={error} />
	) : loading ? (
		<>
			<Loader open={loading} />
			<PageTitle pageName={''} />
			<Header button="back" />
		</>
	) : (
		<>
			<Loader open={loading} />
			{/* <CreativeModal /> */}
			<PageTitle />
			<Header button="menu" />
			<HomeBanner heading="Welcome" showSearchBar={false} />
			<div className="cubeFunctionality">
				{' '}
				<CubeIcon />
			</div>

			<FunctionalityList functionalities={functionalities} />
		</>
	);
};

const mapStateToProps = state => {
	return {
		loading: state.functionalityReducer.loading,
		functionalities: state.functionalityReducer.functionalities,
		error: state.functionalityReducer.error,
	};
};

export default connect(mapStateToProps, { fetchFunctionalities })(FunctionalitiesList);
