import React from 'react';
import Grid from '@material-ui/core/Grid';
import VerticalCard from './VerticalCard';
const VerticalList = ({ verticals }) => {
	return (
		<div className="creative-list-ctn">
			<Grid container spacing={3}>
				{verticals.map(vertical => (
					<VerticalCard vertical={vertical} key={vertical.id} />
				))}
			</Grid>
		</div>
	);
};
export default VerticalList;
