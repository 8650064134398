import ReactGA from 'react-ga';

export const initGA = () => {
	if (process.env.NODE_ENV === 'development') {
		return;
	}
	ReactGA.initialize('UA-159313250-1');
	pageView();
};

export const pageView = () => {
	if (process.env.NODE_ENV === 'development') {
		return;
	}
	ReactGA.pageview(window.location.pathname + window.location.search);
};

export const fireEvent = (category = '', action = '', label = '') => {
	if (process.env.NODE_ENV === 'development') {
		return;
	}
	ReactGA.event({
		category: category,
		action: action,
		label: label,
	});
};
