import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import './Header.scss';
import './LoginModal.scss';
import axios from 'axios';

const LoginForm = () => {
	const [username, setUsername] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [error, setErrorMsg] = React.useState('');
	const handleUsernameChange = e => setUsername(e.target.value);
	const handlePasswordChange = e => setPassword(e.target.value);
	// const handleError = () => setErrorMsg();
	const handleSubmit = () => {
		// const userName = document.getElementById('userName').value;
		// const pwd = document.getElementById('userPassword').value;
		// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
		// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
		// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
		axios
			.post(`${process.env.REACT_APP_API_URL}/auth/local`, {
				identifier: username,
				password: password,
			})
			.then(response => {
				localStorage.setItem('jwt', response.data.jwt);
				window.location.reload();
			})
			.catch(error => setErrorMsg(error.response.data.message[0].messages[0].message));

		// fetch(`${process.env.REACT_APP_API_URL}/auth/local`, {
		// 	method: 'post',
		// 	body: {
		// 		identifier: username,
		// 		password: password,
		// 	},
		// })
		// 	.then(response => {
		// 		localStorage.setItem('jwt', response.data.jwt);
		// 		window.location.reload();
		// 	})
		// 	.catch(error => setErrorMsg(error.response.data.message[0].messages[0].message));

		// axios
		// 	.post(`${process.env.REACT_APP_API_URL}/auth/local`, {
		// 		identifier: username,
		// 		password: password,
		// 	})
		// 	.then(response => {
		// 		// Handle success.
		// 		localStorage.setItem('jwt', response.data.jwt);
		// 		window.location.reload();
		// 	})
		// 	.catch(error => {
		// 		// Handle error.
		// 		console.log('An error occurred:', error);
		// 	});

		// localStorage.setItem('ru', location.pathname);
		// window.location.href = `${process.env.REACT_APP_API_URL}/auth/local`;
		// if (userName === 'Unilever' && pwd === 'Unilever123') {
		// 	// localStorage.setItem('userLoggedIn', true);
		// 	// window.location.reload();
		// } else {
		// 	alert('Please enter valid credentials!');
		// }
	};
	return (
		// <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 2, sm: 4, md: 6 }}>
		// 	<Grid item xs={2} sm={4} md={6} className="loginItems">
		// 		<TextField
		// 			required
		// 			id="userName"
		// 			label="Username"
		// 			defaultValue=""
		// 			// variant="standard"
		// 			value={username}
		// 			onChange={handleUsernameChange}
		// 		/>
		// 	</Grid>
		// 	<Grid item xs={2} sm={4} md={6} className="loginItems">
		// 		<TextField
		// 			required
		// 			variant="standard"
		// 			id="userPassword"
		// 			label="Password"
		// 			type="password"
		// 			autoComplete="current-password"
		// 			onChange={handlePasswordChange}
		// 		/>
		// 	</Grid>
		// 	<Grid item xs={2} sm={4} md={6} className="loginItems">
		// 		<Button onClick={handleSubmit} color="secondary" variant="contained" size="medium">
		// 			Login
		// 		</Button>
		// 	</Grid>
		// </Grid>
		<div className="loginForm">
			<div className="loginItems">
				<input
					type="text"
					name="name"
					required
					value={username}
					onChange={handleUsernameChange}
					placeholder="Username"
					className="input"
				/>
			</div>

			<div className="loginItems">
				<input
					type="password"
					name="name"
					required
					onChange={handlePasswordChange}
					placeholder="Password"
					className="input"
				/>
			</div>

			<Button variant="contained" className="button" onClick={handleSubmit}>
				LOGIN
			</Button>
			<div className="errorMessage">{error}</div>
		</div>
	);
};

export default LoginForm;
