import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import CreativeDetailSection from './CreativeDetailSection';
import { fetchCreative, closeDialogModal } from '../../redux/creative/CreativeActions';
import { hideLoader } from './../../redux/functionality/FunctionalityActions';
import './CreativeModal.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CreativeTagsForModal from './CreativeTags';
import ModalAuthorize from './ModalAuthorize';

import CancelIcon from '@material-ui/icons/Cancel';
const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid gray',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}));
const CreativeModal = ({
	showCreativeModalBox,
	closeDialogModal,
	loading,
	authorizationFailure,
	hideLoader,
}) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const handleClose = () => {
		// setOpen(true);
		closeDialogModal();
	};
	useEffect(() => {}, [showCreativeModalBox]);
	return authorizationFailure ? (
		<>
			<div
				className={showCreativeModalBox ? 'md-modal md-effect-1 md-show' : 'md-modal md-effect-1'}
				id="modal-1"
			>
				<div className="closeModalIcon authorizeIcon" onClick={handleClose}>
					<CancelIcon />
				</div>
				<div class="md-content">
					<ModalAuthorize />
				</div>
			</div>
			<div className="md-overlay" onClick={handleClose}></div>
		</>
	) : (
		<>
			<div
				className={showCreativeModalBox ? 'md-modal md-effect-1 md-show' : 'md-modal md-effect-1'}
				id="modal-1"
			>
				<div className="closeModalIcon" onClick={handleClose}>
					<CancelIcon />
				</div>
				<div className="md-content">
					<CreativeTagsForModal />
					<CreativeDetailSection />
				</div>
			</div>
			<div className="md-overlay" onClick={handleClose}></div>
		</>
	);
};
const mapDispatchToProps = dispatch => {
	return {
		hideLoader: () => dispatch(hideLoader()),
		closeDialogModal: () => dispatch(closeDialogModal()),
	};
};
const mapStateToProps = state => {
	return {
		showCreativeModalBox: state.creativeReducer.showCreativeModalBox,
		loading: state.creativeReducer.loading,
		authorizationFailure: state.creativeReducer.authorizationFailure,
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CreativeModal);
