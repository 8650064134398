import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import './Loader.scss';

const Loader = ({ open }) => {
	return (
		<Backdrop className="loader" open={open}>
			<div className="preloader loading ">
				<span className="slice"></span>
				<span className="slice"></span>
				<span className="slice"></span>
				<span className="slice"></span>
				<span className="slice"></span>
				<span className="slice"></span>
			</div>
		</Backdrop>
	);
};

export default Loader;
