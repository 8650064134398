import React from 'react';
import SearchBar from './SearchBar';
import './HomeBanner.scss';
import HeroImage from '../assets/images/Hero_Image.png';
import { AlternateEmail } from '@material-ui/icons';

const HomeBanner = ({ heading, showSearchBar }) => (
	<div className="homeBanner">
		{showSearchBar ? (
			<div className="bannerContent">
				<h1 className="bannerHeading">{heading}</h1>
				<img src={HeroImage} alt="Creative Gallery" className="heroImg" />
				{/* <SearchBar /> */}
			</div>
		) : (
			''
		)}
	</div>
);

export default HomeBanner;
