import React, { useEffect } from 'react';
import Loader from '../components/Loader';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';
import CreativeTags from '../components/Creative/CreativeTags';
import CreativeDetailSection from '../components/Creative/CreativeDetailSection';
import Authorize from '../components/Creative/Authorize';
import Error from '../components/Error';
import { connect } from 'react-redux';
import { fetchCreative } from '../redux/creative/CreativeActions';
import LoginModal from '../components/LoginModal';

import './../index.css';
const CreativeDetail = ({
	loading,
	error,
	creativeId,
	fetchCreative,
	selectedCreative,
	authorizationFailure,
}) => {
	useEffect(() => {
		fetchCreative(creativeId);
	}, [fetchCreative, creativeId]);

	const isLoggedIn = localStorage.getItem('jwt');

	return error ? (
		<>
			<PageTitle pageName={selectedCreative.title || ''} />
			<Header button="back" />
			<Error error={error} />
		</>
	) : loading ? (
		<>
			<Loader open={loading} />
			<PageTitle pageName={selectedCreative.title || ''} />
			<Header button="back" />
		</>
	) : !isLoggedIn ? (
		<>
			<Header />
			<LoginModal />
		</>
	) : (
		<>
			<Loader open={loading} />
			<PageTitle pageName={selectedCreative.title || ''} />
			<Header button="back" />
			<CreativeTags />
			<div className="creativeDetails_Details_Page">
				<CreativeDetailSection />
			</div>
		</>
	);
};

const mapStateToProps = state => {
	return {
		loading: state.creativeReducer.loading,
		error: state.creativeReducer.error,
		selectedCreative: state.creativeReducer.selectedCreative,
		authorizationFailure: state.creativeReducer.authorizationFailure,
	};
};

export default connect(mapStateToProps, { fetchCreative })(CreativeDetail);
