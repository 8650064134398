import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import LoginForm from './LoginForm';
import './Header.scss';
import './LoginModal.scss';
import Logo from '../assets/images/InMobi_UL_MS_Logo.png';
import LockIcon from '../assets/images/Lock_LoginPopup.png';

export default function LoginModal() {
	const [open, setOpen] = React.useState(true);
	// const handleOpen = () => setOpen(true);
	// const handleClose = () => setOpen(false);

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		border: '2px solid #c3c3c3',
		boxShadow: 24,
		p: 4,
		padding: '3px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		flexFlow: 'wrap',
	};
	return (
		<div>
			{/* <Button onClick={handleOpen} color="primary" className="headerBtn">
				Login
			</Button> */}
			<Modal
				open={true}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style} className="modalBox">
					<div className="loginModal">
						<div className="logoContainer">
							<img src={Logo} alt="InMobi + Mindshare + Unilever" className="logo" />
						</div>
						<div className="loginBoxContainer">
							<Box className="loginBox">
								<div className="welcomeContainer">
									<img src={LockIcon} alt="" className="lockIcon" />
									<div className="welcomeText">
										Welcome to InMobi Creative Gallery. <br />
										Please login to access this website
									</div>
								</div>

								<LoginForm />
							</Box>
						</div>
					</div>
					<div className="borderBottom"></div>
				</Box>
			</Modal>
		</div>
	);
}
