import React, { useEffect } from 'react';
import Loader from '../components/Loader';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';
import HomeBanner from '../components/Home/HomeBanner';
import Error from '../components/Error';
import Cards from '../components/Home/Cards';
import AwardWinningCreatives from '../components/Home/AwardWinningCreatives';
import CubeIcon from '../components/CubeIcon';
import { fetchAllCreatives } from '../redux/creative/CreativeActions';
import { connect } from 'react-redux';
import CreativeModal from '../components/Creative/CreativeModal';
const Home = ({ fetchAllCreatives }) => {
	useEffect(() => {}, [fetchAllCreatives]);

	return (
		<>
			<CreativeModal />
			<PageTitle />
			<Header button="menu" />
			<HomeBanner />
			{/* <CubeIcon /> */}
			<Cards />
			<CubeIcon />
			{/* <AwardWinningCreatives details={selectedCreatives} /> */}
			<AwardWinningCreatives />
		</>
	);
};

export default connect(null, null)(Home);
