import React, { useState, useEffect } from 'react';
import FilterType from './FilterType';
import { connect } from 'react-redux';
import './FiltersSection.scss';
import NoMockSwitch from './NoMockSwitch';
const FiltersSection = ({ filters }) => {
	console.log('filters', filters);
	return Object.keys(filters).length ? (
		<>
			{/* <div className="filterSection1">
				{Object.keys(dummy).map(filterType => (
					<div className="demoFilterType">loading {filterType}</div>
				))}
			</div> */}
			<div className="filterSection">
				{Object.keys(filters).map(filterType => (
					<FilterType name={filterType} filterItems={filters[filterType]} key={filterType} />
				))}
				{/* <div className="filterType">
					<NoMockSwitch />
				</div> */}
			</div>
		</>
	) : (
		// <div className="filterSection1">
		// 	{Object.keys(dummy).map(filterType => (
		// 		<div className="demoFilterType">loading {filterType}</div>
		// 	))}
		// </div>
		<></>
	);
};

const mapStateToProps = state => {
	return {
		filters: state.creativeReducer.filters,
	};
};

export default connect(mapStateToProps)(FiltersSection);
