import axios from 'axios';
import { FETCH_REQUEST, FETCH_FAILURE, FETCH_FAQS_SUCCESS } from './FaqActionTypes';

export const fetchRequest = () => {
	return {
		type: FETCH_REQUEST,
	};
};

export const fetchFailure = error => {
	return {
		type: FETCH_FAILURE,
		payload: error,
	};
};

export const fetchFaqsSuccess = faqs => {
	return {
		type: FETCH_FAQS_SUCCESS,
		payload: faqs,
	};
};

export const fetchFaqs = () => dispatch => {
	dispatch(fetchRequest());
	axios({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/graphql`,
		data: {
			query: `
				query{
					faqs{
						id,
                        question,
                        answer
					}
				}
			`,
		},
	})
		.then(response => {
			return dispatch(fetchFaqsSuccess(response.data.data.faqs));
		})
		.catch(err => dispatch(fetchFailure(err)));
};
