import React from 'react';
import CreativeCarousel from './CreativeCarousel';
import CreativeInfo from './CreativeInfo';
import CreativeAuthor from './CreativeAuthor';
import './CreativeDetailSection.scss';

const CreativeDetailSection = () => {
	return (
		<div className="creativeDetailSection1">
			<div className="creativeDetails">
				<CreativeCarousel />
				<CreativeInfo />
			</div>
			<CreativeAuthor />
		</div>
	);
};

export default CreativeDetailSection;
