import React from 'react';
// import SearchBar from './SearchBar';
import './Cards.scss';
import CreativeCapabilitiesIcon from './../../assets/images/capabilitiesicon.png';
import VerticalSolutionsIcon from './../../assets/images/verticalicon.png';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: '5px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

const Cards = () => {
	const classes = useStyles();
	return (
		// <div className={classes.root}>
		<div className="card">
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Paper className={classes.paper}>
						<div className="border">
							<img src={CreativeCapabilitiesIcon} alt="Creative Capabilities" className="cc_icon" />
							<div className="content">
								<div className="heading">CREATIVE FEATURES</div>
								<div className="cardContent">
									Cutting Edge HTML5 functionalities for high impact, interactive mobile ad experiences.
								</div>
								<Button variant="contained" className="button" href="/functionalities">
									See More
								</Button>
							</div>
						</div>
						<div className="borderBottom"></div>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Paper className={classes.paper}>
						<div className="border">
							<img src={VerticalSolutionsIcon} alt="VERTICAL SOLUTIONS" className="cc_icon" />
							<div className="content">
								<div className="heading">VERTICAL SOLUTIONS</div>
								<div className="cardContent">
									Hand picked, best in class creative examples for every industry type and campaign
									objectives.
								</div>
								<Button variant="contained" className="button" href="/verticals">
									See More
								</Button>
							</div>
						</div>
						<div className="borderBottom"></div>
					</Paper>
				</Grid>
			</Grid>
		</div>
		// </div>
	);
};

export default Cards;
