import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { Link } from '@reach/router';
import { checkLength, getCdnUrl } from '../../utils/string';
import { fireEvent } from '../../utils/tracking';
import { connect } from 'react-redux';
import { selectFunctionality } from '../../redux/functionality/FunctionalityActions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import './CreativeTypeCard.scss';
import CreativeTypeCard from './../Common/CreativeTypeCard';
import hexbox from '../../assets/images/innerHexbox.png';
import hexicon from '../../assets/images/hexicon.png';
const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: '5px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));
const CreativeCardType = ({ details, type }) => {
	console.log(details);
	const classes = useStyles();
	const handleClick = () => {
		fireEvent('Docs Page', 'Click', `${details.name} Clicked`);
		//selectFunctionality();
	};
	return (
		<Grid item xs={12} sm={6} md={4} lg={3} xl={3} className="functionalityCardContainer">
			<Paper className="cardContainer creative-card" square variant="outlined">
				{}
				<Link to={`/${type}/${details.slug}`} onClick={handleClick}>
					<div className="border boxContainer" title={details.name}>
						<div className="creative-card-content">
							<div className="flex-column flex-space-between">
								<div className="card-icon flex-row flex-center">
									{/* <div className="functionalityIcon">
										<img
											src={functionality.icon ? getCdnUrl(functionality.icon.url) : ''}
											alt="Creative Icon"
										/>
									</div> */}
									<div className="verticalIcon">
										{/* <img src={hexbox} alt="Creative Icon" className="outerBox" /> */}
										<div className="hexContainer">
											<img src={details.icon ? getCdnUrl(details.icon.url) : ''} alt="Creative Icon" />
										</div>
									</div>
								</div>
							</div>
							<div className="flex-column flex-space-between">
								<div className="heading">{checkLength(details.name, 24, 20)}</div>
							</div>

							{/* <div className="card-description">{checkLength(functionality.description, 80, 70)}</div> */}

							{/* <div className="flex-column flex-space-between">
								<div className="card-cta">View</div>
							</div> */}
						</div>
					</div>
					<div className="borderBottom"></div>
					{/* <Card className="creative-card" title={functionality.name}>
						<CardContent className="flex-column creative-card-content">

						</CardContent>
					</Card> */}
				</Link>
			</Paper>
		</Grid>
	);
};
export default CreativeCardType;
