import React from 'react';
import Cube from '../assets/images/hexicon.png';
import './CubeIcon.scss';

const CubeIcon = () => {
	return (
		<div className="cubeIcon">
			<img src={Cube} alt="cube" />
		</div>
	);
};

export default CubeIcon;
