import React from 'react';
import './CampaignHighlights.scss';

const CampaignHighlights = ({ selectedCreative }) => {
	return (
		<>
			<div className="sectionTitle">CAMPAIGN HIGHLIGHTS</div>
			{selectedCreative.target_group ? (
				<div className="highlightSections">
					<span className="highlightSectionTitle">TARGET GROUP : </span> {selectedCreative.target_group}
				</div>
			) : (
				<></>
			)}
			{selectedCreative.impressions_delivered ? (
				<div className="highlightSections">
					<span className="highlightSectionTitle">IMPRESSIONS : </span>
					{selectedCreative.impressions_delivered}
				</div>
			) : (
				<></>
			)}
			{selectedCreative.campaign_reach ? (
				<div className="highlightSections">
					<span className="highlightSectionTitle">REACH : </span>
					{selectedCreative.campaign_reach}
				</div>
			) : (
				<></>
			)}
			{selectedCreative.ctr_vcr_engagement_rate ? (
				<div className="highlightSections">
					<span className="highlightSectionTitle">ENGG RATE / CTR / VCR : </span>
					{selectedCreative.ctr_vcr_engagement_rate}
				</div>
			) : (
				<></>
			)}
			{selectedCreative.brand_uplift ? (
				<div className="highlightSections">
					<span className="highlightSectionTitle">BRAND UPLIFT : </span>
					{selectedCreative.brand_uplift}
				</div>
			) : (
				<></>
			)}
		</>
	);
};
export default CampaignHighlights;
