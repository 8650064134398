import React, { useState } from 'react';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import QRCode from 'qrcode.react';
import AddToHomeScreenRoundedIcon from '@material-ui/icons/AddToHomeScreenRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import { useNavigate, useLocation } from '@reach/router';
import { fireEvent } from '../../utils/tracking';
import CreativePreviewLink from './CreativePreviewLink';
import './CreativeActionsSection.scss';
import PreviewLinkIcon from './../../assets/images/previewlinkicon.png';
import PPTIcon from './../../assets/images/ppticon.png';
import SFIcon from './../../assets/images/sficon.png';
import hexContainer from './../../assets/images/hexagoncontainer.png';
const CreativeActionsSection = ({ selectedCreative }) => {
	const isLoggedIn = !!localStorage.getItem('jwt');
	const showPptLink = isLoggedIn && selectedCreative.ppt_pdf_link;
	const showSfLink = isLoggedIn && selectedCreative.salesforce_ticket_link;
	const location = useLocation();

	return (
		<>
			<div className="creativeActionSection">
				<div className="creativeActionWrapper">
					{showPptLink && (
						<div className="creativeActionItem">
							<div className="hexContainer">
								<img src={hexContainer} />
								<div className="iconContainer">
									<a
										href={selectedCreative.ppt_pdf_link}
										target="_blank"
										rel="noopener noreferrer"
										onClick={() =>
											fireEvent('Creative Detail Page', 'Click', `${selectedCreative.title} PPT/PDF Clicked`)
										}
									>
										<img src={PPTIcon} />
									</a>
								</div>
							</div>
						</div>
					)}
					{selectedCreative.preview_link && (
						<div className="creativeActionItem qrCodeActionItem">
							<div className="hexContainer qrCodeContainer">
								<img src={hexContainer} />
								<div className="iconContainer">
									<QRCode value={selectedCreative.preview_link} className="creativeQRCode" />
									<div className="creativeActionText">QR CODE</div>
								</div>
							</div>
							{/* <div className="creativeActionText">QR Code</div>
							<div className="creativeActionImage">
								<QRCode value={selectedCreative.preview_link} className="creativeQRCode" />
							</div> */}
						</div>
					)}
					{selectedCreative.preview_link && (
						<div className="creativeActionItem">
							<div className="hexContainer">
								<img src={hexContainer} />
								<div className="iconContainer">
									<a
										href={selectedCreative.preview_link}
										target="_blank"
										rel="noopener noreferrer"
										onClick={() =>
											fireEvent(
												'Creative Detail Page',
												'Click',
												`${selectedCreative.title} Preview Link Clicked`,
											)
										}
									>
										<img src={PreviewLinkIcon} />
									</a>
								</div>
							</div>
							{/* <div className="creativeActionText">Preview Link</div>
							<div className="creativeActionImage">
								
									<AddToHomeScreenRoundedIcon className="icon" />
								</a>
							</div> */}
						</div>
					)}
					{showSfLink && (
						<div className="creativeActionItem">
							<div className="hexContainer">
								<img src={hexContainer} />
								<div className="iconContainer">
									<a
										href={selectedCreative.salesforce_ticket_link}
										target="_blank"
										rel="noopener noreferrer"
										onClick={() =>
											fireEvent('Creative Detail Page', 'Click', `${selectedCreative.title} SF Link Clicked`)
										}
									>
										<img src={SFIcon} />
									</a>
								</div>
							</div>
							{/* <div className="creativeActionText">SF Ticket Link</div>
						<div className="creativeActionImage">
							<a
								href={selectedCreative.salesforce_ticket_link}
								target="_blank"
								rel="noopener noreferrer"
								onClick={() =>
									fireEvent('Creative Detail Page', 'Click', `${selectedCreative.title} SF Link Clicked`)
								}
							>
								<LinkRoundedIcon className="icon linkIcon" />
							</a>
						</div> */}
						</div>
					)}
					<div className="creativeActionItem">
						<div className="hexContainer">
							<img src={hexContainer} />
							<CreativePreviewLink selectedCreative={selectedCreative} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CreativeActionsSection;
