import {
	FETCH_REQUEST,
	FETCH_FAILURE,
	FETCH_AWARD_WINNING_CREATIVE_SUCCESS,
	HIDE_LOADER,
} from './HomeActionTypes';

const initialState = {
	error: '',
	loading: false,
	selectedCreatives: {},
};

const HomeReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_REQUEST:
			return {
				...state,
				loading: true,
			};

		case FETCH_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case FETCH_AWARD_WINNING_CREATIVE_SUCCESS:
			return {
				...state,
				loading: false,
				selectedCreatives: action.payload,
			};
		case HIDE_LOADER:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default HomeReducer;
