import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useLocation, useNavigate } from '@reach/router';
import { connect } from 'react-redux';
import { fireEvent } from '../../utils/tracking';
import './FilterType.scss';

const FilterType = ({ name, filterItems, selectedFilters = [] }) => {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<Autocomplete
			multiple
			limitTags={1}
			size="small"
			id={`filter${name}`}
			options={filterItems.map(filterItem => filterItem.name)}
			renderInput={params => (
				<TextField {...params} variant="outlined" label={name} className="filterTextField" />
			)}
			className="filterType"
			filterSelectedOptions
			autoHighlight
			value={selectedFilters}
			onChange={(e, newValue = []) => {
				if (newValue.length > selectedFilters.length) {
					// Filter Added
					const [addedFilter] = newValue.filter(filter => !selectedFilters.includes(filter));
					fireEvent('Home Page', 'Click', `${name} - ${addedFilter} Filter Applied`);
					location.search
						? navigate(`${location.href}&${name}=${addedFilter}`)
						: navigate(`${location.href}?${name}=${addedFilter}`);
				} else {
					// Filter Removed
					const removedFilters = selectedFilters.filter(filter => !newValue.includes(filter));
					const removedFiltersStringArray = removedFilters.map(removedFilter => {
						return encodeURI(`${name}=${removedFilter}`);
					});
					console.log(removedFilters, removedFiltersStringArray);

					const currentFilterString = location.search.substr(1);
					const filterStringArray = currentFilterString.split('&');
					for (let i = 0; i < filterStringArray.length; i++) {
						const param = filterStringArray[i];
						if (!param.includes('=')) {
							filterStringArray[i - 1] = `${filterStringArray[i - 1]}&${filterStringArray[i]}`;
							filterStringArray.splice(i, 1);
						}
					}
					const updatedFilterStringArray = filterStringArray.filter(
						filterString => !removedFiltersStringArray.includes(filterString),
					);
					updatedFilterStringArray.length
						? navigate(`${location.origin}${location.pathname}?${updatedFilterStringArray.join('&')}`)
						: navigate(`${location.origin}${location.pathname}`);
				}
			}}
		/>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		selectedFilters: state.creativeReducer.selectedFilters[ownProps.name],
	};
};

export default connect(mapStateToProps)(FilterType);
