import React from 'react';
import './ImageLoader.scss';
const ImageLoader = () => {
	return (
		<div class="preloader loading">
			<span class="slice"></span>
			<span class="slice"></span>
			<span class="slice"></span>
			<span class="slice"></span>
			<span class="slice"></span>
			<span class="slice"></span>
		</div>
	);
};
export default ImageLoader;
