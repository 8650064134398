export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const RESET_CREATIVES = 'RESET_CREATIVES';
export const FETCH_CREATIVES_SUCCESS = 'FETCH_CREATIVES_SUCCESS';
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS';
export const FETCH_CREATIVE_SUCCESS = 'FETCH_CREATIVE_SUCCESS';
export const AUTHORIZATION_FAILURE = 'AUTHORIZATION_FAILURE';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const OPEN_DIALOG_MODAL = 'OPEN_DIALOG_MODAL';
export const CLOSE_DIALOG_MODAL = 'CLOSE_DIALOG_MODAL';
export const SET_SPECIAL_TAGS = 'SET_SPECIAL_TAGS';
