import React, { useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';
import FaqList from '../components/Faq/FaqList';
import { connect } from 'react-redux';
import { fetchFaqs } from '../redux/faq/FaqActions';

const Faqs = ({ fetchFaqs }) => {
	useEffect(() => {
		fetchFaqs();
	}, [fetchFaqs]);

	return (
		<>
			<PageTitle pageName="FAQs" />
			<Header button="menu" />
			<FaqList />
		</>
	);
};

export default connect(
	null,
	{ fetchFaqs },
)(Faqs);
