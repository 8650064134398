import { FETCH_REQUEST, FETCH_FAILURE, FETCH_FAQS_SUCCESS } from './FaqActionTypes';

const initialState = {
	loading: false,
	faqs: [],
	error: '',
};

const faqReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case FETCH_FAQS_SUCCESS:
			return {
				...state,
				loading: false,
				faqs: action.payload,
			};
		default:
			return state;
	}
};

export default faqReducer;
