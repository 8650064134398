import React from 'react';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';
import PageNotFound from '../components/PageNotFound';

const NotFound = () => (
	<>
		<PageTitle pageName="404" />
		<Header button="menu" />
		<PageNotFound />
	</>
);

export default NotFound;
