import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { Link } from '@reach/router';
import Button from '@material-ui/core/Button';
import Lock from './../../assets/images/lock.png';
import './Authorize.scss';
import LoginForm from '../LoginForm';

const ModalAuthorize = () => {
	const [timeLeft, setTimeLeft] = useState(3);
	const location = useLocation();

	useEffect(() => {
		// const timer = setInterval(() => {
		//     if (timeLeft === 1) {
		//         clearInterval(timer);
		//         localStorage.setItem('ru', location.pathname);
		//         window.location.href = `${process.env.REACT_APP_API_URL}/connect/microsoft`;
		//     } else setTimeLeft(prevTime => prevTime - 1);
		// }, 1000);
		// return () => clearInterval(timer);
	}, [timeLeft, location.pathname]);

	return (
		<div className="authorize">
			<img
				src="https://inmobirmapp.blob.core.windows.net/inmobi-rm-app/CreativeRepository/lock_1dfac2af25.png"
				alt="Lock Icon"
				className="lockIcon"
			/>
			<h4 style={{ color: 'red' }}>Sorry! You don't have permission to view this page</h4>
			<span>Please login to access this page</span>
			{/* <a href={`${process.env.REACT_APP_API_URL}/connect/microsoft`}>Tap To Login</a> */}
			<LoginForm />
		</div>
	);
};

export default ModalAuthorize;
