import React from 'react';
import { Helmet } from 'react-helmet';

const PageTitle = ({ pageName = '' }) => (
	<Helmet>
		<title>{pageName ? `${pageName} - InMobi Creative` : 'InMobi Creative'}</title>
	</Helmet>
);

export default PageTitle;
