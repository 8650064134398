import {
	FETCHING_SEARCH_RESULTS,
	FETCH_FAILURE,
	FETCH_SEARCH_SUCCESS,
	CLOSE_SEARCH_RESULTS,
	FETCH_SEARCH_NOTFOUND,
} from './SearchActionTypes';
import { FETCH_CREATIVE_SUCCESS } from '../creative/CreativeActionTypes';

const initialState = {
	loadingSearchResults: false,
	searchResults: [],
	backupResults: [],
	error: '',
	msg: '',
};

const searchReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_SEARCH_RESULTS:
			return {
				...state,
				loadingSearchResults: true,
			};
		case FETCH_FAILURE:
			return {
				...state,
				loadingSearchResults: false,
				error: action.payload,
				searchResults: [],
				msg: '',
			};
		case FETCH_SEARCH_SUCCESS:
			return {
				...state,
				loadingSearchResults: false,
				searchResults: action.payload,
				backupResults: action.payload,
				msg: '',
			};
		case FETCH_SEARCH_NOTFOUND:
			return {
				...state,
				loadingSearchResults: false,
				searchResults: [],
				msg: 'No Result Found',
			};
		case CLOSE_SEARCH_RESULTS:
			return {
				...state,
				loadingSearchResults: false,
				searchResults: [],
				msg: '',
			};
		case FETCH_CREATIVE_SUCCESS:
			return {
				...state,
				searchResults: [],
				msg: '',
			};
		default:
			return state;
	}
};

export default searchReducer;
