import React, { useState, useEffect, useCallback, useRef } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { fetchSearch, closeSearchResults } from '../redux/search/SearchActions';
import { openDialogModal } from './../redux/creative/CreativeActions';
import { useNavigate, useLocation } from '@reach/router';
import Lock from './../assets/images/lock.png';
import './SearchBar.scss';

const SearchBar = ({
	urlSearchInput,
	loadingSearchResults,
	searchResults,
	fetchSearch,
	closeSearchResults,
	openDialogModal,
	msg,
}) => {
	const [searchInput, setSearchInput] = useState(urlSearchInput);
	const [isDirty, setIsDirty] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const searchBarCtn = useRef(null);

	let postsales = '';
	const token = query.get('postsales');
	if (token != null) {
		postsales = 'postsales';
	}
	const openCreativeModal = id => {
		const details = {
			slug: id,
		};
		openDialogModal(details);
	};

	useEffect(() => {
		// Setting initial search value from URL
		setSearchInput(urlSearchInput);
	}, [setSearchInput, urlSearchInput]);

	const debouncedSearch = useCallback(
		AwesomeDebouncePromise(searchInput => {
			fetchSearch(searchInput);
		}, 500),
		[fetchSearch],
	);

	useEffect(() => {
		const handleOutsideClick = e => {
			if (!searchBarCtn.current.contains(e.target)) closeSearchResults();
		};
		if (searchResults.length) {
			document.addEventListener('click', handleOutsideClick);
		} else {
			document.removeEventListener('click', handleOutsideClick);
		}

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, [searchResults, closeSearchResults]);

	const handleChange = e => {
		const value = e.target.value;
		setSearchInput(value);
		debouncedSearch(value);
		setIsDirty(!!value);
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			closeSearchResults();
			if (searchInput) {
				// Search Added
				if (location.search.length) {
					// Filters Exist
					if (location.search.indexOf('search=')) {
						// Search Query Exists Already
						const queryParamsString = location.search.substr(1);
						const queryParamsArray = queryParamsString.split('&');
						const updatedQueryParamsArray = queryParamsArray.filter(
							queryParam => queryParam.indexOf('search=') === -1,
						);
						const finalQueryParamsArray = [...updatedQueryParamsArray, `search=${searchInput}`];
						const updatedQueryParamsString = finalQueryParamsArray.join('&');
						//	navigate(`${location.origin}${location.pathname}?${updatedQueryParamsString}`);
						navigate(`${location.origin}/gallery?${postsales}&search=${searchInput}`);
					} else {
						// Search Query Doesn't Exist
						console.log('Sraech doesnt exist');
						navigate(`${location.href}&search=${searchInput}`);
					}
				} else {
					// Filters Don't Exist
					navigate(`${location.origin}${location.pathname}?search=${searchInput}`);
				}
			} else {
				// Search Removed
				if (location.search.indexOf('search=')) {
					const queryParamsString = location.search.substr(1);
					const queryParamsArray = queryParamsString.split('&');
					const updatedQueryParamsArray = queryParamsArray.filter(
						queryParam => queryParam.indexOf('search=') === -1,
					);
					const updatedQueryParamsString = updatedQueryParamsArray.join('&');
					updatedQueryParamsString
						? navigate(`${location.origin}${location.pathname}?${updatedQueryParamsString}`)
						: navigate(`${location.origin}${location.pathname}`);
				}
			}
		}
	};

	return (
		<div className="searchBarCtn" ref={searchBarCtn}>
			<div
				className={
					loadingSearchResults || searchResults.length || msg != ''
						? 'searchBar searchBarFocus'
						: 'searchBar'
				}
			>
				<div className="searchInputWrapper">
					<SearchIcon className="searchIcon" />
					<input
						type="text"
						name="search-bar"
						className={
							loadingSearchResults || searchResults.length || msg != ''
								? 'searchInput searchInputFocus'
								: 'searchInput'
						}
						placeholder="Search for Keywords like 'Reebok' or 'Food' or 'Carousel'"
						value={searchInput}
						onChange={handleChange}
						autoComplete="off"
						onKeyDown={handleKeyDown}
					/>
					{isDirty && <div className="searchInfoText">Hit enter to see results</div>}
				</div>
			</div>

			{loadingSearchResults ? (
				<ul className="searchResultWrapper">
					<li className="loader">
						<CircularProgress size={30} />
					</li>
				</ul>
			) : (
				<>
					<div
						className={
							searchResults.length ? 'searchScrollerContainer' : 'searchScrollerContainer hidden'
						}
					>
						<ul className={searchResults.length ? 'searchResultWrapper' : 'searchResultWrapper hidden'}>
							{searchResults.length
								? searchResults.map(searchResult => (
										// <Link
										// 	to={'/creative/' + searchResult.slug}
										// 	state={{ detail: searchResult }}
										// 	key={searchResult.id}
										// >
										<div
											onClick={() => {
												openCreativeModal(searchResult.slug);
											}}
										>
											{!searchResult.post_sales ? (
												<li className="searchResult mockupResult">
													<div className="searchResultTitle">
														<div>{searchResult.title}</div>

														<div className="lockContainer">
															<div className="mockup">MOCKUP</div>
														</div>
													</div>
													<div className="searchResultDescription">
														{searchResult.description
															? searchResult.description.length > 80
																? searchResult.description.substr(0, 80) + '...'
																: searchResult.description
															: null}
													</div>
												</li>
											) : (
												<li className="searchResult">
													<div className="searchResultTitle">
														<div>{searchResult.title}</div>
													</div>
													<div className="searchResultDescription">
														{searchResult.description
															? searchResult.description.length > 80
																? searchResult.description.substr(0, 80) + '...'
																: searchResult.description
															: null}
													</div>
												</li>
											)}
										</div>
										// </Link>
								  ))
								: null}
						</ul>
					</div>

					<div
						className={
							searchResults.length == 0 && msg != ''
								? 'searchScrollerContainer'
								: 'searchScrollerContainer hidden'
						}
					>
						<ul
							className={
								searchResults.length == 0 && msg != ''
									? 'searchResultWrapper'
									: 'searchResultWrapper hidden'
							}
						>
							{searchResults.length == 0 && msg != null ? (
								<li className="searchResult">
									<div className="searchResultTitle">{msg}</div>
								</li>
							) : null}
						</ul>
					</div>
				</>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		urlSearchInput: state.creativeReducer.searchInput,
		loadingSearchResults: state.searchReducer.loadingSearchResults,
		searchResults: state.searchReducer.searchResults,
		msg: state.searchReducer.msg,
	};
};

export default connect(mapStateToProps, { fetchSearch, closeSearchResults, openDialogModal })(
	SearchBar,
);
