import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './TagList.scss';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from '@reach/router';
import DoneIcon from '@material-ui/icons/Done';
import { removeQueryStringParameter } from '../../utils/filterDemo';
const TagList = ({ specialTags, creativeCount, totalLength, searchInput, filters }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [noMocks, setNoMocks] = useState(false);
	console.log('Search Input', searchInput);
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const token = query.get('postsales');
		//console.log(token);
		if (token != null) {
			setNoMocks(true);
		}
	}, [location.search]);
	const handleChange = () => {
		setNoMocks(!noMocks);
		if (!noMocks) {
			location.search
				? navigate(`${location.href}&postsales`)
				: navigate(`${location.href}?postsales`);
		} else {
			const removedFiltersStringArray = ['postsales'];
			const currentFilterString = location.search.substr(1);
			const filterStringArray = currentFilterString.split('&');
			const updatedFilterStringArray = filterStringArray.filter(
				filterString => !removedFiltersStringArray.includes(filterString),
			);
			updatedFilterStringArray.length
				? navigate(`${location.origin}${location.pathname}?${updatedFilterStringArray.join('&')}`)
				: navigate(`${location.origin}${location.pathname}`);
		}
	};

	const handleClick = x => {
		const specialtag = encodeURI(`specialtags=${x}`);
		const currentFilterString = location.search.substr(1);
		const filterStringArray = currentFilterString.split('&');
		const updatedFilterStringArray = filterStringArray.filter(
			filterString => filterString != specialtag,
		);

		//const newParamsList = removeQueryStringParameter(location.search, 'specialtags', x); //removeURLParameter("specialtags", location.search);
		//navigate(`${location.origin}${location.pathname}${newParamsList}`);
		updatedFilterStringArray.length
			? navigate(`${location.origin}${location.pathname}?${updatedFilterStringArray.join('&')}`)
			: navigate(`${location.origin}${location.pathname}`);
	};
	const handleRequestDelete = chipMap => {
		//console.log("inside it", chipMap);
	};
	const removeSearchInput = () => {
		const searchQueryDtring = encodeURI(`search=${searchInput}`);

		const currentFilterString = location.search.substr(1);
		const filterStringArray = currentFilterString.split('&');
		console.log(filterStringArray);
		const updatedFilterStringArray = filterStringArray.filter(
			filterString => filterString != searchQueryDtring,
		);

		updatedFilterStringArray.length
			? navigate(`${location.origin}${location.pathname}?${updatedFilterStringArray.join('&')}`)
			: navigate(`${location.origin}${location.pathname}`);
	};
	const newSpecialTags = [];
	for (let i = 0; i < specialTags.length; i++) {
		newSpecialTags.push(specialTags[i]);
	}

	return (
		<>
			<div className="NoMockMainContainer">
				<div className="NoMockContainer">
					{Object.keys(filters).length > 0 && newSpecialTags.length > 0 ? (
						<>
							{newSpecialTags.map(function(tag, i) {
								return (
									<div className="md-chip">
										<span>{tag}</span>
										<button
											type="button"
											className="md-chip-remove"
											onClick={() => handleClick(tag)}
										></button>
									</div>
								);
							})}
						</>
					) : (
						<></>
					)}
					{totalLength > 0 ? (
						<div className="md-chip">
							<span>
								Showing {creativeCount} of {totalLength} results
							</span>
						</div>
					) : (
						<></>
					)}
					{searchInput.length > 0 ? (
						<div className="md-chip">
							<span>{searchInput}</span>
							<button
								type="button"
								className="md-chip-remove"
								onClick={() => removeSearchInput()}
							></button>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</>
	);
};
const mapStateToProps = state => {
	return {
		specialTags: state.creativeReducer.specialTags,
		creativeCount: state.creativeReducer.creativeCount,
		totalLength: state.creativeReducer.backupCreatives.length,
		searchInput: state.creativeReducer.searchInput,
		filters: state.creativeReducer.filters,
	};
};

export default connect(mapStateToProps, null)(TagList);
