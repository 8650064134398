import React, { useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { connect } from 'react-redux';
import HomeBanner from '../components/Verticals/HomeBanner';
import VerticalList from './../components/Verticals/VerticalList';
import { fetchVerticalList } from '../redux/vertical/VerticalActions';
import Error from '../components/Error';
import CubeIcon from '../components/CubeIcon';
import './../components/Verticals/VerticalList.scss';
const VerticalsPage = ({ loading, fetchVerticalList, verticals, error }) => {
	useEffect(() => {
		fetchVerticalList();
	}, [fetchVerticalList]);
	return error ? (
		<Error error={error} />
	) : loading ? (
		<>
			<Loader open={loading} />
			<PageTitle pageName={''} />
			<Header button="back" />
		</>
	) : (
		<>
			<Loader open={loading} />
			{/* <CreativeModal /> */}
			<PageTitle />
			<Header button="menu" />
			<HomeBanner heading="Welcome" showSearchBar={false} />
			<div className="cubeVerticals">
				{' '}
				<CubeIcon />
			</div>

			<VerticalList verticals={verticals} />
		</>
	);
};
const mapStateToProps = state => {
	return {
		loading: state.verticalReducer.loading,
		verticals: state.verticalReducer.verticals,
		error: state.verticalReducer.error,
	};
};
export default connect(mapStateToProps, { fetchVerticalList })(VerticalsPage);
