import {
	FETCH_REQUEST,
	FETCH_FAILURE,
	FETCH_VERTICALS_SUCCESS,
	FETCH_VERTICAL_SUCCESS,
	SELECT_VERTICAL,
	HIDE_LOADER,
} from './VerticalActionTypes';

const initialState = {
	loading: false,
	verticals: [],
	selectedVertical: {},
	error: '',
};

const VerticalReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}
		case FETCH_FAILURE: {
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		}
		case FETCH_VERTICAL_SUCCESS: {
			return {
				...state,
				loading: false,
				selectedVertical: action.payload,
			};
		}
		case FETCH_VERTICALS_SUCCESS: {
			return {
				...state,
				loading: false,
				verticals: action.payload,
			};
		}
		case HIDE_LOADER:
			console.log('inside reducer ');
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default VerticalReducer;
