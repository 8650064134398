import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactMarkdown from 'react-markdown';
import { fireEvent } from '../../utils/tracking';
import './Faq.scss';

const Faq = ({ faq, key }) => {
	const id = key;
	return (
		<Accordion className="faq">
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				onClick={() => fireEvent('Faqs Page', 'Click', `Faq ${faq.id} Clicked`)}
			>
				<ReactMarkdown className="faqQuestion">{faq.question}</ReactMarkdown>
			</AccordionSummary>
			<AccordionDetails>
				<ReactMarkdown className="faqAnswer">{faq.answer}</ReactMarkdown>
			</AccordionDetails>
		</Accordion>
		// <div className="faq-drawer">
		// 	<input className="faq-drawer__trigger" id="name`{id}`" type="checkbox" /><label className="faq-drawer__title" for="faq-drawer">{faq.question}</label>
		// 	<div className="faq-drawer__content-wrapper">
		// 		<div className="faq-drawer__content">
		// 			<p>
		// 				{faq.answer}
		// 			</p>
		// 		</div>
		// 	</div>
		// </div>
	);
};

export default Faq;
