import React from 'react';
import { getCdnUrl } from '../../utils/string';
import examplephonebg from '../../assets/images/phonebgnew.png';
import { openDialogModal } from './../../redux/creative/CreativeActions';
import './AwardWinningCard.scss';
import { getMonthName } from '../../utils/date';
import { connect } from 'react-redux';
import { hideLoader } from './../../redux//home/HomeActions';
const AwardWinningCard = ({ details, openModalBox, hideLoader }) => {
	const openCreativeModal = id => {
		console.log('modal clicked', id);
		openModalBox(id);
		hideLoader();
	};
	const creativeDate = new Date(details.campaign_created_at);
	const formattedDate = `${getMonthName(creativeDate.getMonth())} ${creativeDate.getFullYear()}`;
	return (
		<div
			className="exampleContainer"
			onClick={() => {
				openCreativeModal(details);
			}}
		>
			<img src={examplephonebg} alt="" className="phoneBg" />
			<div className="exampleCardContentSection">
				<div className="cardContent">
					<div className="cardViewBtn">View</div>
					<div className="cardTitle">{details.title}</div>
					<div className="cardRegionDate">
						{details.region ? `${details.region.name}, ${formattedDate}` : `${formattedDate}`}
					</div>
				</div>
				<img src={details.thumbnail ? getCdnUrl(details.thumbnail.url) : ''} />
			</div>
		</div>
	);
};
const mapDispatchToProps = dispatch => {
	return {
		hideLoader: () => dispatch(hideLoader()),
		openModalBox: id => dispatch(openDialogModal(id)),
	};
};
export default connect(null, mapDispatchToProps)(AwardWinningCard);
