import React from 'react';
// import SearchBar from './SearchBar';
import './HomeBanner.scss';
import LabIcons from './../../assets/images/lab_icons.png';
import Grid from '@material-ui/core/Grid';

const HomeBanner = () => (
	<div className="vhomeBanner">
		<div className="content">
			<div className="heading">VERTICAL CREATIVE SOLUTION</div>
		</div>
	</div>
);

export default HomeBanner;
