import axios from 'axios';
import {
	FETCH_REQUEST,
	FETCH_FAILURE,
	FETCH_FUNCTIONALITIES_SUCCESS,
	FETCH_FUNCTIONALITY_SUCCESS,
	SELECT_FUNCTIONALITY,
	HIDE_LOADER,
} from './FunctionalityActionTypes';

export const fetchRequest = () => {
	return {
		type: FETCH_REQUEST,
	};
};

export const fetchFailure = error => {
	return {
		type: FETCH_FAILURE,
		payload: error,
	};
};

export const hideLoader1 = () => {
	console.log('Hide Loader');
	return {
		type: HIDE_LOADER,
	};
};
export const fetchFunctionalitiesSuccess = functionalities => {
	return {
		type: FETCH_FUNCTIONALITIES_SUCCESS,
		payload: functionalities,
	};
};

export const selectFunctionality = slug => {
	return {
		type: SELECT_FUNCTIONALITY,
		payload: slug,
	};
};

export const fetchFunctionalitySuccess = functionality => {
	return {
		type: FETCH_FUNCTIONALITY_SUCCESS,
		payload: functionality,
	};
};

export const fetchFunctionalities = () => dispatch => {
	dispatch(fetchRequest());

	axios({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/graphql`,
		data: {
			query: `query{
				functionalities(where:{published:true}){
				  name,
				  icon{
					url
				  },
				  description,
				  brand_objectives{
					name
				  },
				  slug
				}
			  }`,
		},
	})
		.then(response => {
			dispatch(fetchFunctionalitiesSuccess(response.data.data.functionalities));
		})
		.catch(err => dispatch(fetchFailure(err)));
};

export const fetchFunctionality = slug => dispatch => {
	dispatch(fetchRequest());

	// axios({
	// 	method: 'get',
	// 	url: `${process.env.REACT_APP_API_URL}/functionalities?slug=${slug}`,
	// })
	// 	.then(response => {
	// 		return response.data.length
	// 			? dispatch(fetchFunctionalitySuccess(response.data[0]))
	// 			: dispatch(fetchFailure("Functionality doesn't exist"));
	// 	})
	// 	.catch(err => dispatch(fetchFailure(err)));

	// //graphql
	axios({
		method: 'post',
		url: `${process.env.REACT_APP_API_URL}/graphql`,
		data: {
			query: `query{
					functionalities(where:{slug:"${slug}"}){
						name,
						
						video{
						url
						},icon{
							url
						},
						reporting_metrics{
							name
						}
						osplatforms{
							name
						  },
						brand_objectives{
							name
						}
				  		interaction_rate,
						description,
						faqs{
							question,
							answer
						  }
						slug,
					  
						featured(limit:5){
							title,
							preview_link,
							slug,
							thumbnail{
								url
							},
							campaign_created_at,
							region{
								name
							}

						},
						creatives(limit:5,sort: "createdAt:desc",where:{post_sales:true}){
							title,
									preview_link,
									slug,
									thumbnail{
									url
									},
									campaign_created_at,
									region{
										name
									}
						}
	  
					  complexity
					  }
						}`,
		},
	})
		.then(response => {
			dispatch(fetchFunctionalitySuccess(response.data.data.functionalities[0]));
		})
		.catch(err => dispatch(fetchFailure(err)));
};
export const hideLoader = () => dispatch => {
	console.log('Hide this');
	dispatch(hideLoader1());
};
