import {
	FETCH_REQUEST,
	FETCH_FAILURE,
	RESET_CREATIVES,
	FETCH_CREATIVES_SUCCESS,
	FETCH_FILTERS_SUCCESS,
	FETCH_CREATIVE_SUCCESS,
	AUTHORIZATION_FAILURE,
	SET_SELECTED_FILTERS,
	CLOSE_DIALOG_MODAL,
	OPEN_DIALOG_MODAL,
	SET_SPECIAL_TAGS,
} from './CreativeActionTypes';

const initialState = {
	loading: true,
	creatives: [],
	creativesStartIndex: 0,
	creativeRequestSize: 10,
	isCreativeLeft: true,
	filters: {},
	selectedFilters: {},
	searchInput: '',
	selectedCreative: {},
	authorizationFailure: false,
	error: '',
	showCreativeModalBox: false,
	specialTags: '',
};

const creativeReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case RESET_CREATIVES:
			return {
				...state,
				creatives: [],
				creativesStartIndex: 0,
				isCreativeLeft: true,
			};
		case FETCH_CREATIVES_SUCCESS:
			const isCreativeLeft = (action.payload && action.payload.length) === state.creativeRequestSize;
			return {
				...state,
				loading: false,
				creatives: state.creatives.concat(action.payload),
				creativesStartIndex: state.creativesStartIndex + state.creativeRequestSize,
				isCreativeLeft: isCreativeLeft,
			};
		case FETCH_FILTERS_SUCCESS:
			return {
				...state,
				filters: action.payload,
			};
		case FETCH_CREATIVE_SUCCESS:
			return {
				...state,
				loading: false,
				authorizationFailure: false,
				selectedCreative: action.payload,
			};
		case AUTHORIZATION_FAILURE:
			return {
				...state,
				loading: false,
				authorizationFailure: true,
			};
		case SET_SELECTED_FILTERS:
			return {
				...state,
				selectedFilters: action.payload.filters,
				searchInput: action.payload.searchInput,
			};
		case OPEN_DIALOG_MODAL:
			return {
				...state,
				showCreativeModalBox: true,
			};
		case CLOSE_DIALOG_MODAL:
			return {
				...state,
				showCreativeModalBox: false,
				selectedCreative: {},
			};
		case SET_SPECIAL_TAGS:
			return {
				...state,
				specialTags: action.payload,
			};
		default:
			return state;
	}
};

export default creativeReducer;
