import {
	FETCH_REQUEST,
	FETCH_FAILURE,
	FETCH_FUNCTIONALITIES_SUCCESS,
	FETCH_FUNCTIONALITY_SUCCESS,
	SELECT_FUNCTIONALITY,
	HIDE_LOADER,
} from './FunctionalityActionTypes';

const initialState = {
	loading: false,
	functionalities: [],
	selectedFunctionality: {},
	error: '',
};

const functionalityReducer = (state = initialState, action) => {
	console.log(action.type);
	switch (action.type) {
		case FETCH_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case FETCH_FUNCTIONALITIES_SUCCESS:
			return {
				...state,
				loading: false,
				functionalities: action.payload,
			};
		case FETCH_FUNCTIONALITY_SUCCESS:
			return {
				...state,
				loading: false,
				selectedFunctionality: action.payload,
			};
		case SELECT_FUNCTIONALITY:
			const filteredFunctionality = state.functionalities.filter(
				functionality => functionality.slug === action.payload,
			);
			const selectedFunctionality = filteredFunctionality.length ? filteredFunctionality[0] : {};
			return {
				...state,
				selectedFunctionality: selectedFunctionality,
			};
		case HIDE_LOADER:
			console.log('inside reducer ');
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default functionalityReducer;
