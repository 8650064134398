import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate, useLocation } from '@reach/router';
import ReactMarkdown from 'react-markdown';
import './VerticalDetails.scss';
import mobileScreen from '../../assets/images/hexagontrans.png';
import hexbox from '../../assets/images/innerHexbox.png';
import hexicon from '../../assets/images/hexicon.png';
import { getCdnUrl, getFileType } from '../../utils/string';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CreativeTypeCardDetails from '../Common/CreativeTypeCardDetails';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: '5px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

const AdFormatDetails = ({ details }) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	let desc = details.description;
	if (desc != '' && desc != undefined) {
		const containsString = details.description.search('\n');
		console.log(containsString);
		if (containsString != -1) {
			desc = desc.replace(/(?:\r\n|\r|\n)/g, '\n \n');
		}
	}
	return (
		<>
			<div className="verticalMainContainer">
				<div className="adFormatDetails-Container">
					<div className="ContainerContent">
						<div className="backButtonContent">
							<IconButton
								className="backBtn"
								onClick={() => {
									//fireEvent('Creative Detail Page', 'Click', `Back Button Clicked`);
									navigate(-1);
								}}
							>
								<ArrowBackIcon className="backIcon" />
							</IconButton>
						</div>
						<div className="leftArea">
							<div className="leftContainerHeader">
								<div className="title">VERTICAL SOLUTIONS</div>
								<div className="formatType">{details.name}</div>
								<div className="techDetails">
									{/* <div className="detailsContainer">
										<div className="verticalCardContainer">
											<div className="cardContainer creative-card" square variant="outlined">
												{/* <div className="left">
													<div className="card-iconHex flex-row ">
														 <div className="verticalIcon">
															<img src={hexbox} alt="Creative Icon" />
															<div className="hexContainer">
																<img src={details.icon ? getCdnUrl(details.icon.url) : ''} alt="Creative Icon" />
															</div>
														</div> 
													</div>
												</div> 
												<div className="right">
													<div className="details">
														<div className="verticalIcon">
															<img src={hexbox} alt="Creative Icon" />
															<div className="hexContainer">
																<img src={details.icon ? getCdnUrl(details.icon.url) : ''} alt="Creative Icon" />
															</div>
														</div>
														<ReactMarkdown>{desc}</ReactMarkdown>
													</div>
												</div>
											</div>
										</div> 
									</div>*/}
									<CreativeTypeCardDetails details={details} />
								</div>
							</div>
							{/* <div className="leftContainerDetailsContainer">
								
							</div> */}
						</div>
						{/* <div className="rightArea">
							<div className="hexagonbg">
								<img src={mobileScreen} />
								<div className="adFormatCarouselSection">
									<img src={mobileTrans} />
									<video
										muted
										autoPlay
										playsInline
										preload="auto"
										loop
										controls
										src={details.video ? getCdnUrl(details.video.url) : ''}
										className="creativeCarousel"
									></video>
								</div>
							</div>
						</div> */}
					</div>
				</div>
				<div className="topIconLiner">
					<div className="iconContainer">
						<img src={hexicon} />
					</div>
				</div>
			</div>
		</>
	);
};
export default AdFormatDetails;
